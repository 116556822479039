export default {
  login: require("./media/images/bgLoginSellcoda.png"),
  error404: require("./media/images/404.png"),
  no_image: require("./media/images/no_image.png"),
  product_no_image: require("./media/images/product_no_img.jpg"),
  emptyTable: require("./media/images/emptyTable.png"),
  icp_ladda: require("./media/images/LogoICPLadda.png"),
  icp_international: require("./media/images/LogoICPInter.png"),
  icp_fertilizer: require("./media/images/LogoICPFert.png"),
  product_box: require("./media/images/product-box.png"),
  empty_shop: require("./media/images/empty_shop.png"),
};
